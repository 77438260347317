globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"o2qKZWS_c75JBEHSqNG6q"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    // integrations: [Sentry.captureConsoleIntegration('error')],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click") {
        return {
          ...breadcrumb,
          message: hint?.event?.target?.innerHTML || breadcrumb?.message,
        }
      } else {
        return breadcrumb;
      }
    },
  });
}
