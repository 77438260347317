import { useDialog } from '@/hooks/global/useDialog';
import { useEffect } from 'react';

type Props = {
  ref: React.RefObject<HTMLElement>;
  onClickAway: () => void;
  anchorElement: HTMLElement | null;
};

export const useClickAway = ({ ref, onClickAway, anchorElement }: Props) => {
  // TODO: 現状LocalのuseDialogを使っている箇所の状態を監視できないため、管理方法をNoyamaさんに相談
  const { isOpenDialog } = useDialog();

  // refもしくはanchorElementの外側をクリックしたときのイベントハンドラ制御
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // MEMO: 処理の意図読めず。不要そうであれば消す。
      if (isOpenDialog) return;
      if (anchorElement && anchorElement.contains(event.target as Node)) return

      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, onClickAway, isOpenDialog, anchorElement]);
};
