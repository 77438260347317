import { Popper } from '@/components/molecules/Popper';
import { ConfirmDeleteSetDialog } from '@/features/components/modals/ConfirmDeleteSetDialog';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { FunctionViewType, _TaskParameters } from '@/features/components/steps/BatchUpload/types';
import { memo } from 'react';
import { AccordionRow } from './AccordionRow';
import { Uploader as AccordionRowUploader } from './AccordionRow/Uploader';
import { useBodyHandlers } from './hooks/useBodyHandlers';
import { StyledMenuBox } from './styled';

type Props = {
  handleOpenSetNameDialog: () => void;
  setFunctionViewType: (type: FunctionViewType) => void;
  warningFunction: WarningFunction;
  isSingleSet: boolean;
  _taskParameters: _TaskParameters;
};

export const Body = memo(
  ({
    handleOpenSetNameDialog,
    setFunctionViewType,
    warningFunction,
    isSingleSet,
    _taskParameters,
  }: Props): JSX.Element => {
    const {
      featureData,
      handleOpenDeleteMenu,
      handleOpenDeleteDialog,
      handleSubmit,
      deleteSetName,
      deleteId,
      isOpenPopper,
      anchorElement,
      isOpenConfirmDialog,
      handleCloseConfirmDialog,
      handleClosePopper,
    } = useBodyHandlers();

    return (
      <>
        {featureData?.batch.map((set, i) => (
          <>
            { !isSingleSet ?
              <AccordionRow
                key={`accordion-${i.toString()}`}
                set={set}
                targetNumber={i}
                handleOpenSetNameDialog={handleOpenSetNameDialog}
                handleOpenDeleteMenu={handleOpenDeleteMenu}
                setFunctionViewType={setFunctionViewType}
                warningFunction={warningFunction}
                _taskParameters={_taskParameters}
              />
              :
              <AccordionRowUploader
                key={`accordion-${i.toString()}`}
                targetId={set.id}
                targetNumber={i}
                setFunctionViewType={setFunctionViewType}
                warningFunction={warningFunction}
                variant='singleSet'
                _taskParameters={_taskParameters}
              />
            }
          </>
        ))}
        { !isSingleSet &&
          <>
            <ConfirmDeleteSetDialog
              isOpenDialog={isOpenConfirmDialog}
              handleCloseDialog={handleCloseConfirmDialog}
              handleSubmit={handleSubmit}
              setName={deleteSetName}
              id={deleteId}
            />
            <Popper
              isOpenPopper={isOpenPopper}
              onClose={handleClosePopper}
              anchorElement={anchorElement}
              placement="right"
              placementY={25}
              hasClose={false}
            >
              <StyledMenuBox onMouseDown={handleOpenDeleteDialog}>
                このセットを削除
              </StyledMenuBox>
            </Popper>
          </>
        }
      </>
    );
  },
);
