import { Box } from '@/components/atoms/Box';
import { styled } from '@mui/material';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';

export const StyledPreviewBox = styled(Box)({
  position: 'relative',
  height: '551px',
  maxHeight: '551px',
  zIndex: 2,
});

export const StyledPreview = styled(Swiper, {
  shouldForwardProp: (prop) => prop !== 'swiperIndex' && prop !== 'maxIndex',
})<{
  swiperIndex: number | string;
  maxIndex: number | string;
  width: number;
}>(({ swiperIndex, maxIndex, theme, width }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  width: '512px',
  height: '551px',
  maxHeight: '600px',
  border: `1px solid ${'var(--color-gray-soft)'}`,
  '& .swiper-button-next::after, .swiper-button-prev::after': {
    fontSize: 'var(--font-size-xs)',
    fontWeight: 'normal',
    color: 'var(--color-gray-white)',
    hover: { color: 'var(--color-primary-dark)' },
  },

  '& .swiper-button-prev': {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor:
      swiperIndex === 0 ? 'var(--color-gray-main)' : 'var(--color-gray-deep)',
    opacity: 1,
    '&:hover': { opacity: 0.8 },
  },

  '& .swiper-button-next ': {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    backgroundColor:
      swiperIndex === maxIndex
        ? 'var(--color-gray-main)'
        : 'var(--color-gray-deep)',
    opacity: 1,
    '&:hover': { opacity: 0.8 },
  },
}));

export const StyledPreviewSlide = styled(SwiperSlide)({
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',
  height: '551px',
  '& img': {
    display: 'block',
  },
  backgroundColor: 'transparent',
});

export const StyledPreviewImage = styled(Image)({
  position: 'relative',
  objectFit: 'contain',
});
